<template>
  <v-app class="btx-pos">
    <template v-if="getErrors() && getErrors().length > 0">
      <div class="btx-alert">
        <template v-for="(error, index) in getErrors()">
          <v-alert
            :key="index"
            v-model="error.model"
            transition="slide-y-transition"
            dense
            icon="mdi-alert-circle-outline"
            dismissible
            border="left"
            type="error"
          >
            <span v-html="error.message"></span>
          </v-alert>
        </template>
      </div>
    </template>
    <template v-if="getMessages() && getMessages().length > 0">
      <div class="btx-alert">
        <template v-for="(success, index) in getMessages()">
          <v-alert
            :key="index"
            v-model="success.model"
            transition="slide-y-transition"
            dense
            dismissible
            border="left"
            type="success"
          >
            <span v-html="success.message"></span>
          </v-alert>
        </template>
      </div>
    </template>
    <v-container fluid class="pos-main-container p-0">
      <p class="m-0 py-1 text-center cyan white--text">{{ formatLongDateTime(todayDate) }}</p>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import { filter } from "lodash";
import { getTime } from "date-fns";
export default {
  data() {
    return {
      todayDate: new Date(),
      timeInterval: null,
    };
  },
  mounted() {
    this.timeInterval = setInterval(this.updateTime, 1000);
  },
  methods: {
    updateTime() {
      this.todayDate = new Date();
    },
    getErrors() {
      let result = [];
      const current = getTime(new Date());
      result = filter(this.errors, function (row) {
        return row.expiry > current;
      });
      return result;
    },
    getMessages() {
      let result = [];
      const current = getTime(new Date());
      result = filter(this.messages, function (row) {
        return row.expiry > current;
      });
      return result;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  computed: {
    ...mapGetters(["errors", "messages"]),
  },
};
</script>
